import { ref } from "vue";
import { useUser } from "/composables/useUser";
import { parsePhoneNumberFromString } from "libphonenumber-js";


export default {
  __name: 'index',
  props: {
  iconText: {
    type: String,
    required: false,
  },
  icon: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  stretched: {
    type: Boolean,
    required: false,
    default: false,
  },
  pressed: {
    type: Boolean,
    required: false,
    default: false,
  },
  to: {
    type: [String, Object],
    required: false,
  },
  download: {
    type: String,
    required: false,
  },
  isPrivate: {
    type: Boolean,
    required: false,
    default: false,
  },
  externalLink: {
    type: String,
    required: false,
  },
  phone: {
    type: String,
    required: false,
    validator: function (value) {
      return parsePhoneNumberFromString(value) ? true : false;
    },
  },
  email: {
    type: String,
    required: false,
    validator: function (value) {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        value
      );
    },
  },
  size: {
    type: String,
    required: false,
    validator: function (value) {
      return ["small", "normal", "big"].indexOf(value) !== -1;
    },
    default: "normal",
  },
  type: {
    type: String,
    required: false,
    validator: function (value) {
      return ["button", "submit"].indexOf(value) !== -1;
    },
    default: "button",
  },
  buttonTag: {
    type: String,
    required: false,
    validator: function (value) {
      return ["summary", "button"].indexOf(value) !== -1;
    },
    default: "button",
  },
},
  setup(__props) {

const props = __props;



const { logged } = useUser();
const showModal = ref(false);

function handlePrivate(event) {
  if (props.isPrivate && !logged.value) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    showModal.value = true;
  }
}

return { __sfc: true,props, logged, showModal, handlePrivate }
}

}