import { templateRef } from "@vueuse/core";
import jsonData from "~/assets/json/form-hubspot.json";
import { ref, onMounted, nextTick } from "vue";
import { useStore } from "@nuxtjs/composition-api";
import { uuid } from "vue-uuid";


export default {
  __name: 'index',
  props: {
  variant: {
    type: String,
    required: true,
    validator: function (value) {
      return (
        [
          "demo",
          "newsletter",
          "press",
          "career",
          "showrooms",
          "pella-showroom",
          "milan-showroom",
          "newyork-showroom",
          "customercare",
          "customer-care-and-spare-parts",
          "consulting",
          "salespoints",
          "rfq"
        ].indexOf(value) !== -1
      );
    },
  },
  beforeInit: {
    type: Function,
    required: false,
    default: function (config) {
      console.log("onBeforeFormInit");
      console.log(config);
    },
  },
  ready: {
    type: Function,
    required: false,
    default: function ($form) {
      console.log("ready");
      console.log($form);
    },
  },
  beforeSubmit: {
    type: Function,
    required: false,
    default: function ($form) {
      console.log("onFormSubmit");
      console.log($form);
    },
  },
  submitted: {
    type: Function,
    required: false,
    default: function ($formMessagge) {
      console.log("onFormSubmitted");
      console.log($formMessagge);
    },
  },
  showLoadingOnSubmit: {
    type: Boolean,
    required: false,
    default: false
  }
},
  setup(__props) {

const props = __props;



const tpl_root = templateRef("tpl_root");
const store = useStore();
const isReady = ref(false);
const uid = ref(`hubspot_${uuid.v1("hubspot")}`);
const isSubmitting = ref(false);

const settings = jsonData;

function onFormReady($form) {
  isReady.value = true;
  ScrollTrigger.refresh(true);
  props.ready($form);
}

onMounted(() => {
  nextTick(() => {
    const lang =
      store.state.lang === "en" && store.state.market === "us"
        ? "en_us"
        : store.state.lang;

    hbspt.forms.create(
      Object.assign(
        {
          target: `#${uid.value}`,
          onBeforeFormInit: props.beforeInit,
          onFormReady,
          onFormSubmit: () => {
            isSubmitting.value = true;
            if(props.beforeSubmit) props.beforeSubmit();
          },
          onFormSubmitted: () => {
            isSubmitting.value = false;
            if(props.submitted) props.submitted();
          },
          cssClass: "hubspot-form-integrated",
        },
        settings[props.variant][lang]
      )
    );
  });
});

return { __sfc: true,props, tpl_root, store, isReady, uid, isSubmitting, settings, onFormReady }
}

}