import { render, staticRenderFns } from "./auth.vue?vue&type=template&id=15ac7807"
import script from "./auth.vue?vue&type=script&lang=js"
export * from "./auth.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VectorLogoFantini: require('/var/www/html/fantini/frontend-2/components/vector/logo/fantini/index.vue').default,FormStagingPassword: require('/var/www/html/fantini/frontend-2/components/form/staging-password/index.vue').default})
