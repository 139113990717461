import { ref, watch } from "vue";
import { useStore, useRoute, useRouter } from "@nuxtjs/composition-api";
import { useUser } from "/composables/useUser";
import { useRecaptcha3 } from "/composables/useRecaptcha3.js";


export default {
  __name: 'index',
  props: {
  onSigninSuccess: {
    type: Function,
    required: false,
    default: function () {},
  },
},
  setup(__props) {

const props = __props;



const useAsPasswordRecovery = ref(false);
const isSubmitting = ref(false);
const router = useRouter();

const { login, passwordRecovery } = useUser();
const store = useStore();
const route = useRoute();
const { onSubmit } = useRecaptcha3();

const formValues = ref({
  email:
    route.value.name === "locale-account-activation"
      ? route.value.query.email
      : "",
  password: "",
});

const formErrorsData = ref([]);

watch(useAsPasswordRecovery, () => (formErrorsData.value = []));

async function handleUserLogin() {
  try {
    const recaptchaToken = await onSubmit();
    if (recaptchaToken) {
      isSubmitting.value = true;
      const response = await login(
        formValues.value.email,
        formValues.value.password,
        recaptchaToken
      );

      if (response.token) {
        props.onSigninSuccess();
      } else {
        if (response.code == "[jwt_auth] incorrect_password") {
          formErrorsData.value = [store.state.labels.wrong_password];
        } else if (response.code == "[jwt_auth] empty_username") {
          formErrorsData.value = [store.state.labels.wrong_email];
        } else if (response.error == "not confirmed user") {
          formErrorsData.value = [store.state.labels.not_activated_user_text];
        } else {
          formErrorsData.value = [store.state.labels.something_went_wrong];
        }
      }
      isSubmitting.value = false;
    }
  } catch (e) {
    isSubmitting.value = false;
    formErrorsData.value = [store.state.labels.something_went_wrong];
  }
}

async function handlePasswordRecovery() {
  try {
    const recaptchaToken = await onSubmit();
    if (recaptchaToken) {
      isSubmitting.value = true;
      const response = await passwordRecovery({
        email: formValues.value.email,
        recaptcha: recaptchaToken,
      });

      if (response.status == "success") {
        router.push(`/${store.state.locale}/reset-password-link`);
      } else {
        formErrorsData.value = [store.state.labels.something_went_wrong];
      }

      isSubmitting.value = false;
    }
  } catch (e) {
    formErrorsData.value = [store.state.labels.something_went_wrong];
    isSubmitting.value = false;
  }
}

return { __sfc: true,props, useAsPasswordRecovery, isSubmitting, router, login, passwordRecovery, store, route, onSubmit, formValues, formErrorsData, handleUserLogin, handlePasswordRecovery }
}

}