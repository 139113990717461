import { computed, onMounted } from "vue";
import { templateRef } from "@vueuse/core";


export default {
  __name: 'index',
  props: {
  name: {
    type: String,
    required: false,
  },
  label: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: false,
  },
  options: {
    type: [Object, Array],
    required: false,
  },
  optionGroups: {
    type: Object,
    required: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  validation: {
    type: String,
    required: false,
  },
  validationMessages: {
    type: Object,
    required: false,
  },
},
  setup(__props) {

const props = __props;



const tpl_root = templateRef("tpl_root");
const inputClass = computed(() => {
  let interactivityClasses = props.disabled
    ? ""
    : "hover:bg-skin-surface hover:text-skin-base hover:border-current";

  return `w-full h-14 !border-t-0 !border-r-0 !border-l-0 border border-current text-skin-muted placeholder-shown:!text-skin-muted valid:!text-skin-base focus:!outline-none focus:ring-transparent focus:bg-skin-surface focus:text-skin-base focus:border-current transition-colors duration-300 ease-in-out ${interactivityClasses}`;
});

onMounted(()=> {
  if(props.placeholder) {
    tpl_root.value.$el.getElementsByTagName('option')[0].removeAttribute('hidden');
  }
});

return { __sfc: true,props, tpl_root, inputClass }
}

}