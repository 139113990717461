import Vue from "vue";
import VueFormulate from "@braid/vue-formulate";
import { ref } from "vue";
import { useStaging } from "/composables/useStaging";
import { it, es, en, fr, de, ru, zh } from "@braid/vue-formulate-i18n";
import { useRecaptcha3 } from "/composables/useRecaptcha3.js";


export default {
  __name: 'index',
  setup(__props) {

Vue.use(VueFormulate, {
  plugins: [it, es, en, fr, de, ru, zh],
  locale: `it`,
  validationNameStrategy: ["validationName", "label"],
});

const { login } = useStaging();
const { onSubmit } = useRecaptcha3();

const formValues = ref({
  username: "",
  password: "",
});

const formErrorsData = ref([]);

async function submitHandler() {
  const recaptchaToken = await onSubmit();

  const data = await login(
    formValues.value.username,
    formValues.value.password,
    recaptchaToken
  );
  if (data.response == "success") {
    window.location.href = "/";
    return;
  } else {
    formErrorsData.value = [`An error occurred`];
  }
}

return { __sfc: true,login, onSubmit, formValues, formErrorsData, submitHandler }
}

}