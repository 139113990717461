import { computed } from "vue";
import { useStore } from "@nuxtjs/composition-api";

export const useStaging = () => {
  const store = useStore();
  async function login(username, password, recaptcha) {
    return await store.dispatch("stagingLogin", {
      username,
      password,
      recaptcha,
    });
  }

  return {
    login,
  };
};
