import { ref } from "vue";
import { useMediaQuery } from "@vueuse/core";
import useTailwindCss from "/composables/useTailwindCss";


export default {
  __name: 'index',
  props: {
  initiallyOpen: {
    type: Boolean,
    required: false,
    default: false,
  },
  capHeight: {
    type: Boolean,
    required: false,
    default: false,
  },
  containOverscroll: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  setup(__props) {

const props = __props;



const { screens } = useTailwindCss();
const isLGScreen = useMediaQuery(`(min-width: ${screens.lg.min})`);
const open = ref(props.initiallyOpen);
// adds "open" attribute on start
const openOnStart = open.value ? { [`open`]: `` } : null;

function handleToggle(event) {
  open.value = typeof event.currentTarget.getAttribute("open") == "string";
  ScrollTrigger.refresh(true);
}

return { __sfc: true,props, screens, isLGScreen, open, openOnStart, handleToggle }
}

}