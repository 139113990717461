export default {
  __name: 'index',
  props: {
  visible: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  emits: ["hide"],
  setup(__props, { emit }) {

const props = __props;





function handleSuccess() {
  emit("hide");
}

return { __sfc: true,props, emit, handleSuccess }
}

}