import { render, staticRenderFns } from "./index.vue?vue&type=template&id=24c55dc4"
import script from "./index.vue?vue&type=script&setup=true&lang=js"
export * from "./index.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputSelect: require('/var/www/html/fantini/frontend-2/components/input/select/index.vue').default,BtnPill: require('/var/www/html/fantini/frontend-2/components/btn/pill/index.vue').default})
