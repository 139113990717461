import { computed } from "vue";


export default {
  __name: 'index',
  props: {
  variant: {
    type: String,
    required: false,
    validator: function (value) {
      return ["small", "normal", "big"].indexOf(value) !== -1;
    },
    default: "normal",
  },
  serie: {
    type: String,
    required: false,
  },
  design: {
    type: String,
    required: false,
  },
  collections: {
    type: Array,
    required: false,
  },
  finish: {
    type: String,
    required: false,
  },
},
  setup(__props) {

const props = __props;



const collectionsList = computed(() => {
  return props.collections?.length ? [...props.collections].join(", ") : [];
});

const numCollections = computed(() => {
  return props.collections?.length ? props.collections.length : 0;
});

return { __sfc: true,props, collectionsList, numCollections }
}

}