export default {
  __name: 'index',
  props: {
  grpx: {
    type: String,
    required: true,
    validator: function (value) {
      return (
        [
          "info",
          "arrow-angle-b",
          "localisation",
          "zoom-in",
          "zoom-out",
          "search",
          "plus",
          "minus",
          "hamburger",
          "play",
          "close",
          "favourite",
          "success",
          "danger",
          "gallery",
          "download",
          "account",
          "arrow-long-r-light",
          "arrow-long-l-light",
          "arrow-long-up-light",
          "chevron-t-regular",
          "chevron-r",
          "chevron-b-regular",
          "chevron-l",
          "angle-b",
          "angle-t",
          "delete",
          "edit",
          "duplicate",
          "list",
          "newsletter",
          "quote",
          "filters",
          "facebook",
          "linkedin",
          "pinterest",
          "instagram",
          "twitter",
          "youtube",
          "houzz",
          "vimeo",
          "play",
          "pause",
          "play",
          "sound-on",
          "sound-off",
          "fullscreen-on",
          "fullscreen-off",
          "doc",
          "share",
          "unlocked",
          "locked",
        ].indexOf(value) !== -1
      );
    },
  },
  size: {
    type: String,
    required: true,
    validator: function (value) {
      return ["2", "3", "4", "6", "8", "10", "16", "24"].indexOf(value) !== -1;
    },
  },
},
  setup(__props) {

const props = __props;



return { __sfc: true,props }
}

}